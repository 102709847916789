<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Water can be split by electrolysis into
        <chemical-latex content="H2(g)" />
        and
        <chemical-latex content="O2(g)" />
        as shown below:
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="2 H2O(g) <=> 2 H2(g) + O2(g)" />
      </p>

      <p class="mb-3">
        If this reaction yields partial pressures of
        <stemble-latex content="$0.0900\text{ bar,}$" />
        <number-value :value="pH2" unit="\text{bar,}" />
        and
        <number-value :value="pO2" unit="\text{bar}" />
        for
        <chemical-latex content="H2O(g)," />
        <chemical-latex content="H2(g)," />
        and
        <chemical-latex content="O2(g)," />
        respectively, what is the value of the equilibrium constant,
        <stemble-latex content="$\text{K}_\text{p},$" />
        for this reaction?
      </p>

      <calculation-input
        v-model="inputs.Kp"
        prepend-text="$\text{K}_\text{p}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question119',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Kp: null,
      },
    };
  },
  computed: {
    pH2() {
      return this.taskState.getValueBySymbol('pH2').content;
    },
    pO2() {
      return this.taskState.getValueBySymbol('pO2').content;
    },
  },
};
</script>
